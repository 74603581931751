@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&family=Playfair+Display:wght@400;700&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  --font-sans: 'Inter', sans-serif;
  --font-serif: 'Playfair Display', serif;
  --color-black: #000;
  --color-white: #fff;
  --color-gray: #555;
  --color-gray-dark: #333;
  --color-gray-light: #888;
  --color-gray-lighter: #ddd;
}
body {
  font-family: var(--font-serif);
  /* background-image: url('./img/bg/bg-grunge.jpg');
  background-size:cover; */
}
h1,
h2,
h3,
h4,
h5,
.display-1,
.display-2,
.display-3 {
  font-family: var(--font-sans);
  font-weight: 700;
}
.font-serif {
  font-family: var(--font-serif);
}
.font-sans {
  font-family: var(--font-sans);
}
#header > .container,
#about,
#skills,
#work,
#contact {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subtitle {
  position: relative;
}
.subtitle::after {
  content: '';
  width: 8rem;
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-4rem);
  height: 0.1rem;
  background-color: var(--color-gray-lighter);
  border-radius: 1rem;
}
nav {
  flex-grow: 1;
  align-self: stretch;
}
.nav-link {
  color:var(--color-black)
}
.nav-link span {
  position: relative;
  padding-bottom: 2px;
}
.nav-link span::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: var(--color-black);
  border-radius: 1rem;
  transform: scaleX(0);
  
  transform-origin: 0% 50%;
  transition: transform ease 0.8s;
}
.nav-link span:hover::after {
  transform: scaleX(1);
}
.common-link {
  text-decoration: none;
  color: var(--color-gray-dark);
  vertical-align: top;
  transition: all linear 0.5s;
}
.common-link:hover {
  color: var(--color-black);
  transform: scale(1.1);
}
.hide-text-link {
  line-height: 0;
  font-size: 0;
  color: transparent
}

#hero {
  flex-grow: 50;
  align-self: stretch;
}
.icons {
  font-size: 1.5rem;
}
.title-text {
  /* background-image: url('./img/bg/black.jpg'); */

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}
/* BACK TO TOP BUTTON */
.back-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--color-white);
  color: var(--color-gray-dark);
  transition: all ease 0.8s;
}
/* ABOUT */
#about p {
  max-width: 80ch;
}

/* //PROjECTS */
.project-img {
  width: 100%;
  max-width: 45rem;
  padding: 1rem;
  filter: grayscale(100%);
  border-radius: 1rem;
  transition: all ease 1s;
}
.project-img:hover {
  filter: grayscale(0);
}
.project-thumbnail {
  max-width: 20rem;
}
.project-text {
  font-size: 1.2rem;
}
.project-tags,
.project-links {
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.project-tags li {
  color: var(--color-white);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  margin: 1rem;
  background-color: var(--color-gray-dark);
}
.project-links li {
  padding: 0px;
  padding: 0.3rem 1rem;
  margin: 1rem;
  border-radius: 1rem;
  border: solid 1px var(--color-black);
  transition: all ease 1s;
}

.project-links li a {
  padding: 0px;
  color: var(--color-black);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 1s;
}

.project-links li:hover a {
  color: var(--color-white);
}
.project-links li:hover {
  background-color: var(--color-black);
}

.project-thumb {
  cursor: pointer;
}
.project-thumb p.h3 {
  position: relative;
}
.project-thumb p.h3::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: var(--color-gray-dark);
  border-radius: 1rem;
  transform: scaleX(0);

  transform-origin: 0% 50%;
  transition: transform ease 1s;
}
.project-thumb:hover p.h3::after {
  transform: scaleX(1);
}

/* CONTACT FORM */
@media (min-width: 800px) {
  form {
    width: 80%;
  }
}


/*MODAL*/
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  z-index: 99;
  overflow: scroll;
}
.center-content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0px;
  line-height: 0.7;
  cursor: pointer;
  width: auto;
  transform: rotate(0deg);
  transition: all linear 0.3s;
  z-index: 99;
}

.close-modal:hover {
  transform: rotate(180deg);
}
